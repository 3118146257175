import React from "react";
import {useTranslation} from "react-i18next";

import SEO from "../components/Seo/Seo";
import LocalizedLink from "../components/LocalizedLink/LocalizedLink";

const NotFoundPage = ({ location }) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <SEO
                title={t("meta.404.title")}
                lang={i18n.language}
                description={t("meta.home.description")}
                pathname={location.pathname}
            />
            <div className="Privacy">
                <section className="mask-3-light">
                    <div className="container text-center">
                        <h1 className="text-white">{t("notFoundPage.title")}</h1>
                        <br />
                        <LocalizedLink to="/" className="btn btn-link text-light">{t("notFoundPage.subtitle")}</LocalizedLink>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NotFoundPage
